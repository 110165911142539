<template>
    <v-container>

        <AError
        :api="this.api"/>
        <v-btn
        class="my-3"
        color="primary"
        @click="closeDetail">
            <v-icon>
                mdi-arrow-left-bold
            </v-icon>
        </v-btn>
        <v-card
        color="secondary">
            <div class="text-h3 pa-5 white--text">
                Subscriptions 
            </div>
            <!-- <p class="px-5 white--text">{{ company.companyName }}</p> -->
            <v-list 
            color="">
            <v-skeleton-loader
            ref="skeleton"
            type="card-avatar"
            v-if="this.api.isLoading">
            </v-skeleton-loader>
                <v-list-item
                v-for="(item,index) in subscription"
                    :key="index">
                    <v-container>
                        <v-card>
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{item.plan_name}}
                                </div>
                                <!-- <div class="d-flex justify-start mb-2">
                                    {{ item.name2 }}
                                </div> -->
                                <div class="d-flex justify-start" v-if="item.price!=null">
                                    <p>
                                        RM {{ item.price }}
                                    </p>
                                </div>
                                    <div class="d-flex justify-start "
                                    v-if="item.coupon">
                                        <p
                                        class="text-decoration-line-through">
                                            RM {{ item.price }} 
                                        </p>
                                        <div class="ml-2">
                                            ({{ item.coupon }})
                                        </div>
                                    </div>

                                    <div class="d-flex justify-start "
                                    >
                                        <p
                                        class="">
                                            Status :
                                        </p>
                                        <div class="ml-2 font-weight-bold">
                                            {{ item.stripe_status }} 
                                        </div>
                                    </div>

                                    <div class="d-flex justify-start"  v-if="item.total_discount!=null && item.total_discount!='0'">
                                    Discount : 
                                    <p class="font-weight-bold">
                                        RM {{item.total_discount.substring(0,item.total_discount.length-2) }}
                                    </p>
                                </div>
                                <div class="d-flex justify-start">
                                    Price  : 
                                    <p class="font-weight-bold" v-if="item.price!=null && item.price!='0'">
                                        RM {{ item.price }}
                                    </p>
                                    <p v-else class="font-weight-bold">
                                        RM 0
                                    </p>
                                </div>
                                <hr>
                                <!-- <p class="d-flex justify-start">
                                    {{ item.description }}
                                </p> -->
                                <p class="d-flex justify-start font-weight-bold">
                                    {{ item.verification }}
                                    ( {{ convertTimeZone(item.created_at) }} - {{ convertTimeZone(item.renewal_at) }})
                                </p>
                                <!-- <p class="d-flex justify-start font-weight-bold">
                                    ( {{ item.created_at }} - {{ item.ended_at }})
                                </p> -->
                            </div>
                        </v-card>
                    </v-container>
                </v-list-item>
            </v-list>
        </v-card>
    </v-container>
</template>

<script>
export default{
    data: () => ({
        company:null,
        subscription:null,
       
        api:{
            isLoading:false,
            isError:false,
            isSuccesful:false,
            error:null,
            url:null,
        }
        }),
        created()
        {
            this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp)=>
        {
            if(resp.class=='getSubscription'){
                this.subscription = resp.data.subscription;
                this.api.isLoading = false;

            }
        }
        },
        mounted(){
            this.fetch();
            this.fetchSubscription();
            
        },
        methods:{
            closeDetail(){
                this.$router.go(-1);
            },
            fetch()
            {
                this.company = this.$router.history.current.params.id;
                
            },
            fetchSubscription()
            {
                this.api.method = "get";
                this.api.url = process.env.VUE_APP_SERVER_API+"/subscription/"+this.$router.history.current.params.id;
                this.$api.fetch(this.api);
            },
            convertTimeZone(time){
                return this.$moment(time).format('LLL');
            }
        }
}
</script>